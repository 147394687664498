.country-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
  background-color: #f0f4f8;
}

.country-selection h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.flag-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  max-width: 600px;
}

.flag-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.flag-button:hover, .flag-button:focus {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.flag-button img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.country-name {
  margin: 0.5rem 0 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

@media (min-width: 640px) {
  .country-selection h2 {
    font-size: 1.5rem;
  }

  .flag-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .country-name {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .flag-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* iOS-specific styles */
@supports (-webkit-touch-callout: none) {
  .country-selection {
    padding-top: calc(1rem + env(safe-area-inset-top));
  }
}