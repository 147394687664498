.sound-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }
  
  .sound-selection h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4a90e2;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .sound-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .sound-button {
    background-color: #ffffff;
    border: 1px solid #4a90e2;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    color: #4a90e2;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .sound-button:hover {
    background-color: #4a90e2;
    color: #ffffff;
  }
  
  @media (min-width: 768px) {
    .sound-list {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .sound-button {
      width: calc(50% - 0.5rem);
    }
  }