@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f4f8;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .main-content {
    padding: 2rem;
  }
}