.exercise-card {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  margin: 16px auto;
  width: calc(100% - 32px);
  max-width: 400px;
  overflow: hidden;
  border-top: 4px solid #4a90e2;
  transition: all 0.3s ease;
}

.card-content {
  padding: 20px;
}

.exercise-type {
  font-size: 0.875rem;
  font-weight: 600;
  color: #4a90e2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
  display: block;
}

.exercise-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.exercise-content {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.word-container {
  display: inline-flex;
  margin-right: 0.5rem;
}

.syllable {
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
}

.play-button {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
}

.play-button:hover {
  color: #2563eb;
  transform: scale(1.1);
}

.tip-section {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 20px;
}

.tip-section h3 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #4b5563;
  margin: 0 0 4px 0;
}

.tip-section p {
  font-size: 0.8125rem;
  color: #6b7280;
  margin: 0;
  line-height: 1.4;
}

.record-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  width: 100%;
}

.record-button:hover {
  background-color: #2563eb;
}

.record-button.recording {
  background-color: #ef4444;
}

.loading {
  text-align: center;
  margin-top: 20px;
  color: #4b5563;
  font-size: 0.875rem;
}

.recording-player {
  margin-top: 20px;
}

.recording-player h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 10px;
}

.recording-player audio {
  width: 100%;
}

.report-section {
  margin-top: 24px;
  border-top: 1px solid #e5e7eb;
  padding-top: 20px;
}

.report-section h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 12px;
}

.report-summary {
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 16px;
  padding: 10px;
  background-color: #f3f4f6;
  border-radius: 8px;
}

.score-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 20px;
}

.score-item {
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}

.score-item span {
  display: block;
  font-size: 0.75rem;
  color: #6b7280;
}

.score-item .score {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 4px;
}

.score-excellent {
  background-color: #d1fae5;
  color: #065f46;
}

.score-good {
  background-color: #e0f2fe;
  color: #0369a1;
}

.score-needs-improvement {
  background-color: #fef3c7;
  color: #92400e;
}

.report-section h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #4b5563;
  margin: 16px 0 12px;
}

.word-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 12px;
}

.word-item {
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}

.word-item span {
  display: block;
  font-size: 0.875rem;
  color: inherit;
}

.word-item .score {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 4px;
}

@media (min-width: 640px) {
  .exercise-card {
    margin: 24px auto;
  }

  .card-content {
    padding: 24px;
  }

  .exercise-content {
    font-size: 1.75rem;
  }
}