.app-header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .app-header h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #4a90e2;
  }
  
  .selected-country {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #666;
  }
  
  .selected-country img {
    border-radius: 2px;
  }